/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (sprachwelten.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/pure.css');
require('../css/pure-resp.css');
require('../css/app.css');
// awesome font
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = $;
global.jQuery = $;

//
require ('../scss/custom_bootstrap.scss');
require ('../scss/notepaper/notepaper/scss/style.scss');

//dropdown
global.Popper = require ('popper.js');
import 'bootstrap/js/dist/util.js';
import 'bootstrap/js/dist/dropdown.js';
import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/scrollspy.js';

//menu selection
window.addEventListener('load', function () {

    (function (window, document) {

        var layout   = document.getElementById('layout'),
            menu     = document.getElementById('menu'),
            menuLink = document.getElementById('menuLink'),
            content  = document.getElementById('main');

        function toggleClass(element, className) {
            var classes = element.className.split(/\s+/),
                length = classes.length,
                i = 0;

            for(; i < length; i++) {
                if (classes[i] === className) {
                    classes.splice(i, 1);
                    break;
                }
            }
            // The className is not found
            if (length === classes.length) {
                classes.push(className);
            }

            element.className = classes.join(' ');
        }

        function toggleAll(e) {
            var active = 'active';

            e.preventDefault();
            toggleClass(layout, active);
            toggleClass(menu, active);
            toggleClass(menuLink, active);
        }

        menuLink.onclick = function (e) {
            toggleAll(e);
        };

        content.onclick = function(e) {
            if (menu.className.indexOf('active') !== -1) {
                toggleAll(e);
            }
        };

    }(this, this.document));
})

